import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styles from './UserSettingsMenu.module.css';
import {LiaProductHunt} from "react-icons/lia";
import {PiBellRinging, PiCompassBold} from "react-icons/pi";
import {HiOutlineQuestionMarkCircle} from "react-icons/hi2";
import {PiStarLight} from "react-icons/pi";
import {PiBugBeetleLight} from "react-icons/pi";
import {RxExclamationTriangle} from "react-icons/rx";
import {CiLogout} from "react-icons/ci";
import {HiChevronRight} from "react-icons/hi2";
import {useTheme} from "../ThemeContext/ThemeContext";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import {SiHomeassistantcommunitystore} from "react-icons/si";
import {RiShoppingCartLine} from "react-icons/ri";
import {useNavVisibility} from "../NavVisibilityContext/NavVisibilityContext";
import {GiOpenBook} from "react-icons/gi";
import useNotifications from "../../hooks/useNotifications";
import {BsFillPersonVcardFill} from "react-icons/bs";
import {TbFriends} from "react-icons/tb";
import {ReactComponent as ContactIcon} from "../../assets/contact.svg";


const UserSettingsMenu = ({ user, includeHello, includeLogout, handleShowHeader, handleShowSettingsHeader, handleLogout, setShowTour }) => {
    const { isDarkMode, toggleTheme } = useTheme();
    const { setIsNavVisible } = useNavVisibility();
    const { platform, subscription, permission, subscribeUser, unsubscribeUser, error } = useNotifications();
    const [isSubscribing, setIsSubscribing] = useState(false);
    const [dealAlertText, setDealAlertText] = useState('Deal Alerts')
    const navigate = useNavigate();

    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);

        if (window.innerWidth <= 1200) {
            setIsNavVisible(false);
        }

        const handleResize = () => {
            if (window.innerWidth <= 1200) {
                setIsNavVisible(false);
            } else {
                setIsNavVisible(true);
            }
        };

        window.scrollTo(0,0);

        window.addEventListener('resize', handleResize);

        // Clean up function to remove the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleShowHeader, handleShowSettingsHeader]);

    useEffect(() => {
        console.log('SUBSCRIPTION VAL', subscription);
        if(subscription){
            setDealAlertText('Subscribed to deal alerts');
        }
    }, [subscription]);

    const handleTutorialClick = (e) => {
        e.preventDefault();
        setShowTour(true);
        navigate('/');
    };

    const handleSubscribe = async () => {
        // Must be triggered by user interaction on iOS
        setIsSubscribing(true);
        setDealAlertText('Subscribing...')
        const response = await subscribeUser();
        setIsSubscribing(false);

        console.log(response);
        if (response.success === true) {
            setDealAlertText('Subscribed to deal alerts')
        } else if (response.success === 'failedPermission') {
            setDealAlertText(response.message);
        } else {
            setDealAlertText('Failed to enable to deal alerts. Try again...')

        }
    };

    const handleUnsubscribe = async () => {
        // Must be triggered by user interaction on iOS
        setIsSubscribing(true);
        setDealAlertText('Unsubscribing...')
        const response = await unsubscribeUser();
        setIsSubscribing(false);

        console.log(response);
        if (response.success === true) {
            setDealAlertText('Unsubscribed from deal alerts')
        } else if (response.success === 'failedPermission') {
            setDealAlertText(response.message);
        } else {
            setDealAlertText('Failed to disable to deal alerts. Try again...')

        }
    };

    const handleDealAlertClick = async () => {
        if(subscription){
            return handleUnsubscribe();
        }

        return handleSubscribe();
    }

    const menuItems = {
        mainSection: [
            { link: "/account-settings", icon: BsFillPersonVcardFill, text: "Account Settings", includeArrow: true },
            { link: "/menu-preferences", icon: LiaProductHunt, text: "Menu Preferences", includeArrow: true },
            { link: "/brand-preferences", icon: RiShoppingCartLine, text: "Brand Preferences", includeArrow: true },
            { link: "/dispensary-preferences", icon: SiHomeassistantcommunitystore, text: "Your Dispensaries", includeArrow: true },
            {
                link: "#",
                icon: PiBellRinging,
                text: dealAlertText,
                includeArrow: true,
                onClick: handleDealAlertClick
            },

            { link: "/contact", icon: ContactIcon, text: "Contact Us", includeArrow: true, customIconClass: 'contactPath' },
            /*{ link: "/review", icon: PiStarLight, text: "Review On App Store & Google Play", includeArrow: true },*/
            { link: "/referral", icon: TbFriends, text: "Refer a Friend", includeArrow: true },
            //{ link: "/report-deal-issue", icon: RxExclamationTriangle, text: "Report Deal Issue", includeArrow: true },
            {
                link: "#",
                icon: GiOpenBook,
                text: "App Tutorial",
                includeArrow: true,
                onClick: handleTutorialClick
            },
        ],
        bottomLinks: [],
    };

    const renderMenuItem = ({ link, icon: Icon, text, includeArrow, customClass, onClick, customIconClass }) => (
        <li key={text} className={`${styles.menuItem} ${customClass ? styles[customClass] : ''}`}>
            <Link
                to={link}
                className={styles.menuLink}
                onClick={onClick}
            >
                <div className={styles.iconAndName}>
                    <div className={`${styles.iconWrap}`}>
                        {Icon && <Icon className={`${styles.menuIcon} ${customIconClass ? styles[customIconClass]: ''}`}/>}
                    </div>
                    {text}
                </div>
                {includeArrow && <span className={styles.arrow}><HiChevronRight/></span>}
            </Link>
        </li>
    );

    return (
        <nav className={styles.userSettingsMenu}>
            <ul className={styles.menuList}>

                <div className={styles.userSectionHelloWrap}>
                    <div className={styles.userSectionHello}>
                        <h2 className={styles.sectionHeader}>Hello, {user.name}</h2>
                    </div>
                </div>


                <div className={styles.section}>
                    {menuItems.mainSection.map(renderMenuItem)}
                </div>

                <div className={styles.bottomLinks}>
                    {menuItems.bottomLinks.map(renderMenuItem)}
                </div>

                <div className={styles.toggleContainer}>
                    <ThemeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme}/>
                </div>


            </ul>
        </nav>
    );
};

export default UserSettingsMenu;

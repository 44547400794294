import React, {useState} from 'react';
import {useTheme} from "../ThemeContext/ThemeContext";
import constants from "../../utils/constants";

const WelcomeScreen = ({ onClose, onAgeConfirm, onOpenLogin, logoSrc }) => {
    // Use your existing theme context for the toggle functionality
    const { toggleTheme } = useTheme();
    const [currentView, setCurrentView] = useState('account');

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50" style={{ zIndex: 999999999 }}>
            <div className="w-full h-full mx-auto" >
                <div className={`w-full h-full bg-white dark:bg-dark-bg shadow-lg flex justify-center`}>
                    <div className="rounded-xl p-8 my-auto mx-auto flex flex-col justify-center items-center " style={{ maxWidth: window.innerWidth >= 787 ? '640px' : '100%' }}>
                        {/* Logo */}
                        <div className="flex justify-center mb-8">
                            <div className="h-[75px] w-[333px] font-bold flex items-center justify-center text-black dark:text-white">
                                <img src={logoSrc} className="w-auto h-auto max-w-[100%] max-h-[100%]" alt="logo"/>
                            </div>
                        </div>



                        {currentView === 'initial' && (
                            <>
                                {/* Main heading */}
                                <h1 className="text-4xl font-bold mb-6 text-center text-black dark:text-white">
                                    Ready to find deals?
                                </h1>

                                {/* Subheading */}
                                <p className="text-xl mb-10 text-center text-gray-600 dark:text-gray-300">
                                    Welcome to BETA v1—We're still in the lab, expect improvements!
                                </p>
                                <div className="mb-8">
                                    <p className="text-xl mb-6 text-center text-black dark:text-white">
                                        Are you 21+?
                                    </p>
                                    <div className="flex justify-center space-x-4">
                                        <button
                                            className="py-3 px-8 rounded-lg text-white font-semibold bg-brand-purple hover:bg-purple-700 transition-colors border-0"
                                            onClick={() => {
                                                setCurrentView('account');
                                            }}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            className="py-3 px-8 rounded-lg text-white font-semibold bg-brand-purple hover:bg-purple-700 transition-colors border-0"
                                            onClick={() => {
                                                if (onAgeConfirm) onAgeConfirm(false);
                                                if (onClose) onClose();
                                            }}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </>

                        )}

                        {currentView === 'account' && (
                            <>
                                <div className="text-sm mb-6 text-center text-gray-600 dark:text-gray-300">
                                    <p className={`mb-1 py-0`}>Welcome to BETA v1</p> <p className={`py-0 my-2 text-xs`}>We are in the lab, expect a higher<br/> experience with each update!</p>
                                </div>
                                {/* Main heading */}
                                <h1 className="text-4xl font-bold mb-6 text-center text-black dark:text-white">
                                    Ready to find deals?
                                </h1>

                                {/* Subheading */}
                                <p className="text-xl mb-10 text-center text-gray-600 dark:text-gray-300">
                                    Receive deal alerts, save deals, set deal preferences and more with your pluug account.
                                </p>
                                <div className="mb-6 flex flex-col justify-center">
                                    <button
                                        className="w-full flex items-center justify-center py-3 px-6 rounded-lg mb-1 bg-brand-purple hover:bg-purple-700 text-white font-semibold transition-colors border-0"
                                        onClick={() => {
                                            onOpenLogin(true);
                                            onClose();
                                        }}
                                    >
                                        Sign In or Sign Up
                                    </button>

                                    <div className="flex items-center my-4">
                                        <div className="flex-grow h-px bg-gray-300 dark:bg-gray-700"></div>
                                        <span className="px-4 text-gray-500 dark:text-gray-400">or</span>
                                        <div className="flex-grow h-px bg-gray-300 dark:bg-gray-700"></div>
                                    </div>

                                    <button
                                        className="py-3 px-8 rounded-lg text-white font-semibold bg-brand-purple hover:bg-purple-700 transition-colors border-0"
                                        onClick={() => {
                                            if (onAgeConfirm) onAgeConfirm(true);
                                        }}
                                    >
                                        Shop Now
                                    </button>

                                </div>
                            </>

                        )}


                        {/* Sign in options */}

                        {/* Terms and Privacy Policy */}
                        <p className="text-sm text-center text-gray-500 dark:text-gray-400">
                            By continuing, you confirm that you are of <br /> <span className={`font-bold text-white`}>legal age in your jurisdiction</span> <br />and you agree to our

                            <a href={constants.TOS_URL} target={`_blank`} className="dark:text-brand-green text-brand-purple hover:underline ml-1 mr-1">Terms of Use</a>
                            and
                            <a href={constants.PRIVACY_URL} target={`_blank`} className="dark:text-brand-green text-brand-purple  hover:underline ml-1">Privacy Policy</a>.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default WelcomeScreen;

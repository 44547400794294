import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import useApi from '../../utils/useApi';
import styles from './UserSelectFavoriteBrands.module.css';
import logo from '../../assets/logo2.svg';
import {useTheme} from "../ThemeContext/ThemeContext";
import {useNavVisibility} from "../NavVisibilityContext/NavVisibilityContext";
import {ArrowLeft, Loader2} from "lucide-react";
import {useBrands} from '../BrandContext/BrandContext';

const UserSelectFavoriteBrands = ({
                                      handleShowSettingsHeader,
                                      handleShowHeader,
                                      geoposition,
                                      onComplete,
                                      user
                                  }) => {
    const {
        brands,
        brandsSelected,
        brandsUnselected,
        isLoading,
        error,
        searchBrands,
        loadBrands,
        updateBrandSelection,
        getSelectedBrandIds
    } = useBrands();
    const { isDarkMode } = useTheme();
    const logoSrc = isDarkMode ? logo : logo;
    const { setIsNavVisible } = useNavVisibility();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [saveText, setSaveText] = useState('Save');

    const api = useApi();

    useEffect(() => {
        if(user){
            handleShowHeader(false);
            handleShowSettingsHeader(true);
        } else {
            handleShowHeader(false);
            handleShowSettingsHeader(false);
        }

        setIsNavVisible(false);

        console.log('Brands');
        console.log(brands);
        console.log(isLoading);
        if (brands.length === 0 && !isLoading) {
            console.log('Should load brands');
            loadBrands(api, geoposition);
        }
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        searchBrands(api, searchTerm);
        return () => searchBrands.cancel();
    }, [searchTerm]);

    const handleBrandSelect = (brandId) => {
        let brand = brandsUnselected.find(b => b.id === brandId);
        if(!brand){
            brand = brandsSelected.find(b => b.id === brandId);
        }
        updateBrandSelection(brandId, !brand.selected);
    };

    const handleNext = async () => {
        try {
            const selectedBrandIds = getSelectedBrandIds();
            await api.saveFavoriteBrands(selectedBrandIds);
            onComplete();
            navigate('/');
        }
        catch (error) {
            console.error('Error saving selected brands:', error);
        }
    };

    const handleSkip = () => {
        onComplete();
        navigate('/');
    }

    const savePreferences = async () => {
        try {
            setIsSaving(true);
            const selectedBrandIds = getSelectedBrandIds();
            await api.saveFavoriteBrands(selectedBrandIds);
            setIsSaving(false);
            navigate('/user-settings');
            loadBrands(api, geoposition);
        }
        catch (error) {
            console.error('Error saving selected brands:', error);
        }
    };

    if (error) {
        return (
            <div className={styles.container}>
                <div className={styles.error}>
                    Error loading brands. Please try again later.
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {!user && (
                <div className={styles.logoContainer}>
                    <img className={styles.logo} src={logoSrc} alt="logo"/>
                </div>
            )}
            <div className={styles.titleContainer}>
                {!user && (
                    <button
                        className={styles.backButton}
                        onClick={() => navigate('/select-products')}
                        aria-label="Go back to select product types"
                    >
                        <ArrowLeft size={24}/>
                    </button>
                )}

                <h3 className={styles.title}>
                    {isLoading ? (
                        <span>Loading brands near you...</span>
                    ) : (
                         <span>We found <span className={styles.brandColor}>{brands.length} brands</span> near you!</span>
                     )}
                </h3>
            </div>

            <p className={styles.subtitle}>Select your favorite brands to add your brand preferences.</p>

            <div className="mb-6 w-full">
                <input
                    type="text"
                    placeholder="Search brands..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="max-w-md m-auto p-2 rounded-lg border border-gray-300 dark:border-gray-600
                             bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                             focus:ring-2 focus:ring-[#98fb98] dark:focus:ring-[#775496]"
                />
            </div>

            {isLoading && (<div className={styles.loading}>Loading brands...</div>)}
            {!isLoading && searchTerm ? (
                <>
                    <div className={styles.dispensaryGrid}>
                        {brandsUnselected.map((brand) => (
                            <button
                                key={brand.id}
                                className={`${styles.dispensaryButton} ${brand.selected ? styles.selected : ''}`}
                                onClick={() => handleBrandSelect(brand.id)}
                            >
                                <div className={styles.logoImage} style={{ backgroundImage: `url(${brand.logo})` }}>
                                    <img src={brand.logo} alt={brand.name} style={{ display: 'none' }}/>
                                </div>
                                <span>{brand.name}</span>
                            </button>
                        ))}
                        {brandsSelected.map((brand) => (
                            <button
                                key={brand.id}
                                className={`${styles.dispensaryButton} ${brand.selected ? styles.selected : ''}`}
                                onClick={() => handleBrandSelect(brand.id)}
                            >
                                <div className={styles.logoImage} style={{ backgroundImage: `url(${brand.logo})` }}>
                                    <img src={brand.logo} alt={brand.name} style={{ display: 'none' }}/>
                                </div>
                                <span>{brand.name}</span>
                            </button>
                        ))}

                    </div>
                </>
            ) : (
                <div className={styles.dispensaryGrid}>
                    {brandsSelected.map((brand) => (
                        <button
                            key={brand.id}
                            className={`${styles.dispensaryButton} ${brand.selected ? styles.selected : ''}`}
                            onClick={() => handleBrandSelect(brand.id)}
                        >
                            <div className={styles.logoImage} style={{ backgroundImage: `url(${brand.logo})` }}>
                                <img src={brand.logo} alt={brand.name} style={{ display: 'none' }}/>
                            </div>
                            <span>{brand.name}</span>
                        </button>
                    ))}
                    {brandsUnselected.map((brand) => (
                        <button
                            key={brand.id}
                            className={`${styles.dispensaryButton} ${brand.selected ? styles.selected : ''}`}
                            onClick={() => handleBrandSelect(brand.id)}
                        >
                            <div className={styles.logoImage} style={{ backgroundImage: `url(${brand.logo})` }}>
                                <img src={brand.logo} alt={brand.name} style={{ display: 'none' }}/>
                            </div>
                            <span>{brand.name}</span>
                        </button>
                    ))}
                </div>
            )}

            <div className={`${styles.buttonContainer} ${styles.visible}`}>
                {!user && (
                    <>
                        <button className={styles.skipButton} onClick={handleSkip}>Skip & start deal shopping</button>
                        <button
                            className={styles.nextButton}
                            onClick={handleNext}
                            disabled={isLoading}
                        >
                            Start shopping
                        </button>
                    </>
                )}
                {user && (
                    <button
                        className={styles.nextButton}
                        onClick={savePreferences}
                        disabled={isLoading}
                    >
                        {isSaving && (
                            <span className={`flex flex-row gap-2 items-center`}>Saving... <Loader2 className="w-4 h-4 animate-spin"/></span>
                        )}

                        {!isSaving && (
                            <span>{saveText}</span>
                        )}
                    </button>
                )}
            </div>
        </div>
    );
};

export default UserSelectFavoriteBrands;

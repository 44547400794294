import React, { createContext, useState, useEffect, useContext } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme === 'dark') {
            return true;
        } else if (savedTheme === 'light') {
            return false;
        } else {
            return true;
        }
    });

    useEffect(() => {
        document.documentElement.classList.toggle('dark', isDarkMode);

        const root = document.documentElement;
        if (isDarkMode) {
            root.style.setProperty('--nav-background', 'var(--dark-nav-background)');
            root.style.setProperty('--price-border', 'var(--price-border-dark)');
            root.style.setProperty('--abs-background', 'var(--dark-abs-background)');
            root.style.setProperty('--settings-icon', 'var(--dark-settings-icon)');
            root.style.setProperty('--background-accent-color', 'var(--dark-black-background)');
            root.style.setProperty('--background-color', 'var(--dark-background)');
            root.style.setProperty('--background-transparent', 'var(--dark-background-transparent)');
            root.style.setProperty('--text-color', 'var(--dark-text)');
            root.style.setProperty('--text-color-secondary', 'var(--dark-text-secondary)');
            root.style.setProperty('--primary-color', 'var(--dark-primary)');
            root.style.setProperty('--secondary-color', 'var(--dark-secondary)');
            root.style.setProperty('--thirdary-color', 'var(--dark-thirdary)');
            root.style.setProperty('--accent-color', 'var(--dark-accent)');
            root.style.setProperty('--vote-green', 'var(--dark-vote-green)');
            root.style.setProperty('--icon-transparent', 'var(--dark-icon-transparent)');
            root.style.setProperty('--discount-badge', 'var(--light-discount-badge)');
            root.style.setProperty('--price-color', 'var(--light-accent)');
            root.style.setProperty('--product-border', 'var(--product-border-dark)');
            root.style.setProperty('--focus-input-color', 'var(--dark-focus-input-color)');
            root.style.setProperty('--branding-color', 'var(--branding-green)');
            root.style.setProperty('--search-tab-fg', 'var(--dark-search-tab-fg)');
            root.style.setProperty('--search-tab-bg', 'var(--dark-search-tab-bg)');
            root.style.setProperty('--product-bg-transparent', 'var(--dark-product-bg-transparent)');
            root.style.setProperty('--pill-bg', 'var(--dark-pill-bg)');
            root.style.setProperty('--pull', 'var(--dark-pull)');


        } else {
            root.style.setProperty('--nav-background', 'var(--light-nav-background)');
            root.style.setProperty('--price-border', 'var(--price-border-light)');
            root.style.setProperty('--abs-background', 'var(--light-abs-background)');
            root.style.setProperty('--settings-icon', 'var(--light-settings-icon)');
            root.style.setProperty('--background-accent-color', 'var(--light-accent-background)');
            root.style.setProperty('--background-color', 'var(--light-background)');
            root.style.setProperty('--background-transparent', 'var(--light-background-transparent)');
            root.style.setProperty('--text-color', 'var(--light-text)');
            root.style.setProperty('--text-color-secondary', 'var(--light-text-secondary)');
            root.style.setProperty('--primary-color', 'var(--light-primary)');
            root.style.setProperty('--secondary-color', 'var(--light-accent-background)');
            root.style.setProperty('--thirdary-color', 'var(--light-thirdary)');
            root.style.setProperty('--accent-color', 'var(--light-accent)');
            root.style.setProperty('--vote-green', 'var(--light-vote-green)');
            root.style.setProperty('--icon-transparent', 'var(--light-icon-transparent)');
            root.style.setProperty('--discount-badge', 'var(--light-discount-badge)');
            root.style.setProperty('--price-color', 'var(--branding-purple)');
            root.style.setProperty('--product-border', 'var(--product-border-light)');
            root.style.setProperty('--focus-input-color', 'var(--light-focus-input-color)');
            root.style.setProperty('--branding-color', 'var(--branding-purple)');
            root.style.setProperty('--search-tab-fg', 'var(--light-search-tab-fg)');
            root.style.setProperty('--search-tab-bg', 'var(--light-search-tab-bg)');
            root.style.setProperty('--product-bg-transparent', 'var(--light-product-bg-transparent)');
            root.style.setProperty('--pill-bg', 'var(--light-pill-bg)');
            root.style.setProperty('--pull', 'var(--light-pull)');



        }
        localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    const toggleTheme = () => {
        setIsDarkMode(prevMode => !prevMode);
    };

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../../utils/useApi';
import styles from './UserNearbyDispensaries.module.css';
import logo from '../../assets/logo2.svg';
import { useTheme } from "../ThemeContext/ThemeContext";
import { useNavVisibility } from "../NavVisibilityContext/NavVisibilityContext";
import {ArrowLeft, Loader2} from "lucide-react";

const UserNearbyDispensaries = ({handleShowSettingsHeader, handleShowHeader, geoposition, user, onboarding = false}) => {
    const [dispensaries, setDispensaries] = useState([]);
    const [count, setCount] = useState(0);
    const [filteredDispensaries, setFilteredDispensaries] = useState([]);
    const [selectedDispensaries, setSelectedDispensaries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [saveText, setSaveText] = useState('Save');
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const { isDarkMode } = useTheme();
    const logoSrc = isDarkMode ? logo : logo;
    const { setIsNavVisible } = useNavVisibility();
    const navigate = useNavigate();
    const api = useApi();

    useEffect(() => {
        if(user){
            handleShowHeader(false);
            handleShowSettingsHeader(true);
        } else {
            handleShowHeader(false);
            handleShowSettingsHeader(false);
        }
        window.scrollTo(0,0);
        setIsNavVisible(false);

        fetchDispensaries();
    }, []);

    const fetchDispensaries = async () => {
        setIsLoading(true);
        try {
            let query = {
                geo: geoposition,
                sortBy: 'near_by',
                page: 1,
                distance: 30,
                asArray: true
            }

            const results = await api.selectFavoriteBusinesses(query);
            if (results.data.length > 0) {
                setError(null);

                setCount(results.count);
                setDispensaries(results.data);
                setFilteredDispensaries(results.data);
                const initialSelected = results.data
                                               .filter(dispensary => dispensary.selected)
                                               .map(dispensary => dispensary.id);
                setSelectedDispensaries(initialSelected);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching nearby dispensaries:', error);
            setError('Error loading dispensaries. Please try again later.');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (searchTerm.trim() === '') {
            setFilteredDispensaries(dispensaries);
            return;
        }

        const searchDispensaries = async () => {
            setIsLoading(true);
            try {
                let query = {
                    geo: geoposition,
                    sortBy: 'near_by',
                    page: 1,
                    distance: 10,
                    term: searchTerm,
                    asArray: true,
                }

                const results = await api.selectFavoriteBusinesses(query);

                setCount(results.count);
                setFilteredDispensaries(results.data || []);
                const initialSelected = results.data
                                               .filter(dispensary => dispensary.selected)
                                               .map(dispensary => dispensary.id);
                setSelectedDispensaries(initialSelected);
            } catch (error) {
                console.error('Error searching dispensaries:', error);
                // Keep the current filtered list on error
            } finally {
                setIsLoading(false);
            }
        };

        // Add debounce for better UX
        const timeoutId = setTimeout(searchDispensaries, 300);
        return () => clearTimeout(timeoutId);
    }, [searchTerm, geoposition]);

    const handleDispensarySelect = (dispensaryId) => {
        setSelectedDispensaries(prev =>
                                    prev.includes(dispensaryId)
                                    ? prev.filter(id => id !== dispensaryId)
                                    : [...prev, dispensaryId]
        );
    };

    const handleNext = async () => {
        try {
            setIsSaving(true);
            await api.saveFavoriteDispensaries(selectedDispensaries);
            setIsSaving(false);
            navigate('/select-products');
        } catch (error) {
            console.error('Error saving selected dispensaries:', error);
        }
    };

    const handleSkip = () => {
        navigate('/select-products');
    }

    const savePreferences = async () => {
        try {
            setIsSaving(true);
            await api.saveFavoriteDispensaries(selectedDispensaries);
            setIsSaving(false);

            navigate('/user-settings');
        } catch (error) {
            console.error('Error saving selected dispensaries:', error);
        }
    };


    return (
        <div className={styles.container}>
            {!user && (
                <div className={styles.logoContainer}>
                    <img className={styles.logo} src={logoSrc} alt="logo"/>
                </div>
            )}

            <div className={styles.titleContainer}>
                <h3 className={styles.title}>
                    {isLoading ? (
                        <span>Loading dispensaries near you...</span>
                    ) : (
                         <span>We found <span className={styles.brandColor}>{count} dispensaries</span> near you!</span>
                     )}
                </h3>
            </div>

            {onboarding ? (
                <p className={styles.subtitle}>
                    Select your go-to dispensaries to add to your dispensary preferences, or type a location below to save it. You can also update these in your profile settings later.
                </p>
            ): (
                 <p className={styles.subtitle}>
                     Select your go-to dispensaries to add to your dispensary preferences, or type a location below to save it.
                 </p>
             )}

            <div className="mb-6 w-full">
                <input
                    type="text"
                    placeholder="Search dispensaries..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 rounded-lg border border-gray-300 dark:border-gray-600
                    m-auto
                             bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                             focus:ring-2 focus:ring-[#98fb98] dark:focus:ring-[#775496] max-w-md"
                />
            </div>

            {isLoading && (<div className={`${styles.loading} mb-2`}>Loading dispensaries...</div>)}
            {!isLoading && filteredDispensaries.length === 0 && (
                <div className={styles.noResults}>No dispensaries found matching your search.</div>
            )}

            <div className={styles.dispensaryGrid}>
                {filteredDispensaries.map((dispensary) => (
                    <button
                        key={dispensary.id}
                        className={`${styles.dispensaryButton} ${
                            selectedDispensaries.includes(dispensary.id) ? styles.selected : ''
                        }`}
                        onClick={() => handleDispensarySelect(dispensary.id)}
                    >
                        <div
                            className={styles.logoImage}
                            style={{ backgroundImage: `url(${dispensary.logoImage})` }}
                        >
                            <img src={dispensary.logoImage} alt={dispensary.name} style={{ display: 'none' }} />
                        </div>
                        <span className={`font-bold`}>{dispensary.name}</span>
                        <span className={`mt-1.5`}>{dispensary.city}, {dispensary.state}</span>
                    </button>
                ))}

            </div>

            <div className={`${styles.buttonContainer} ${styles.visible}`}>
                {!user && (
                    <>
                        <button className={styles.skipButton} onClick={handleSkip}>Skip this Step</button>
                        <button className={styles.nextButton} onClick={handleNext}>
                            Next (1/3)
                        </button>
                    </>
                )}
                {user && (
                    <button className={styles.nextButton} onClick={savePreferences}>
                        {isSaving && (
                            <span className={`flex flex-row gap-2 items-center`}>Saving... <Loader2 className="w-4 h-4 animate-spin"/></span>
                        )}

                        {!isSaving && (
                            <span>{saveText}</span>
                        )}
                    </button>
                )}
            </div>
        </div>
    );
};

export default UserNearbyDispensaries;

import React, {useState, useEffect, useCallback, useRef, useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styles from "../App/App.module.css";
import SearchBar from "../SearchBar/SearchBar";
import MapComponent from "../MapComponent/MapboxMapComponent";
import MapLoader from "../MapLoader/MapLoader";
import NavbarFilter from "../NavbarFilter/NavbarFilter";
import useApi from "../../utils/useApi";
import ProductListVirtual from "../ProductListVirtual/ProductListVirtual";
import {HighlightDispensaryProvider} from "../../contexts/HighlightDispensaryProvider";

const Results = ({ user, geoposition, handleShowHeader, handleShowSettingsHeader, handleShowDate, changeGeoPosition, showTour, setShowTour }) => {
    const [retryCount, setRetryCount] = useState(0);
    const [products, setProducts] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [noDeals, setNoDeals] = useState(false);
    const [filterCounts, setFilterCounts] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [category, setCategory] = useState(2);
    const [categoryName, setCategoryName] = useState('Flower');
    const [mapMarker, setMapMarker] = useState(null);
    const [sortBy, setSortBy] = useState('near_you');
    const [distance, setDistance] = useState(3);
    const [apiDistance, setApiDistance] = useState(null);
    const [isApiDistanceUpdate, setIsApiDistanceUpdate] = useState(false);
    const [priceFrom, setPriceFrom] = useState(null);
    const [priceTo, setPriceTo] = useState(null);
    const [weight, setWeight] = useState(null);
    const [weightUnit, setWeightUnit] = useState(null);
    const [cbdFrom, setCbdFrom] = useState(null);
    const [cbdTo, setCbdTo] = useState(null);
    const [thcFrom, setThcFrom] = useState(null);
    const [thcTo, setThcTo] = useState(null);
    const [strainType, setStrainType] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [noResults, setNoResults] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const MemoizedMapComponent = React.memo(MapComponent);
    const searchBarRef = useRef(null);
    const { searchGeoProducts, searchGeoBusinesses, getFilterCounts } = useApi();
    const navbarFilterRef = useRef(null);
    const [isNavbarFilterPinned, setIsNavbarFilterPinned] = useState(false);
    const [navbarFilterOriginalTop, setNavbarFilterOriginalTop] = useState(0);

    const handleBusinessClick = (business) => {
        navigate(`/business/${business.id}`);
    }

    // Memoize the MapComponent with its props
    const memoizedMap = useMemo(() => {
        if (!businesses || businesses.length === 0) {
            return <MapLoader/>;
        }
        return <MapComponent mapMarker={mapMarker}
                             onBusinessClick={handleBusinessClick}
                             businesses={businesses}
                             geoposition={geoposition}/>;
    }, [businesses, mapMarker]); // Only re-render when businesses or mapMarker changes

    // Memoize search parameters to prevent unnecessary API calls
    const searchParams = useMemo(() => ({
        searchTerm,
        geoposition,
        category,
        sortBy,
        distance,
        priceFrom,
        priceTo,
        weight,
        cbdFrom,
        cbdTo,
        thcFrom,
        thcTo,
        strainType
    }), [
                                     searchTerm, geoposition, category, sortBy, distance,
                                     priceFrom, priceTo, weight, cbdFrom, cbdTo,
                                     thcFrom, thcTo, strainType
                                 ]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const setInitialPosition = () => {
            if (navbarFilterRef.current) {
                const rect = navbarFilterRef.current.getBoundingClientRect();
                setNavbarFilterOriginalTop(rect.top + window.pageYOffset);
            }
        };

        setInitialPosition();
        window.addEventListener('load', setInitialPosition);
        window.addEventListener('resize', setInitialPosition);

        return () => {
            window.removeEventListener('load', setInitialPosition);
            window.removeEventListener('resize', setInitialPosition);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {

            const windowWidth = window.innerWidth;
            const scrollY = window.pageYOffset;
            const unpinOffset = windowWidth >= 778 ? 30 : 50;

            //console.log('NAV BAR ORIGINAL TOP: ' + navbarFilterOriginalTop);
            //console.log('SCROLL Y: ' + scrollY);
            //console.log('SCROLL UNPIN: ' + (navbarFilterOriginalTop - unpinOffset));

            if (scrollY > navbarFilterOriginalTop) {
                setIsNavbarFilterPinned(true);
            } else if (scrollY <= navbarFilterOriginalTop - unpinOffset) {
                setIsNavbarFilterPinned(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [navbarFilterOriginalTop]);

    useEffect(() => {
        handleShowHeader(true);
        handleShowSettingsHeader(false);
        handleShowDate(true);
    }, []);

    const handleSearchResults = useCallback((results) => {
        setIsSearching(false);
        setRetryCount(0);
        if (results.data.length <= 0 && !results.pagination) {
            setHasMore(false);
            return;
        }

        setNoDeals(results.noResults);

        setProducts(prevProducts => (page === 1 ? results.data : [...prevProducts, ...results.data]));
        setHasMore(results.pagination.next_page_url);
        setPage(prevPage => prevPage + 1);
        setNoResults(results.pagination.total === 0);

        if(results.distance !== distance && results.distance <= 50){
            setIsApiDistanceUpdate(true); // Flag that this is an API-initiated update
            setDistance(results.distance);
            setApiDistance(results.distance);
        }
    }, [page]);

    const handleSetBusinesses = useCallback((results) => {
        if (results.data?.length > 0) {
            setBusinesses(results.data);
        }
    }, []);

    const resetSearch = useCallback((newSearch) => {
        setProducts([]);
        setBusinesses([])
        setPage(1);
        setHasMore(true);
        setNoResults(null);
        if(newSearch){
            //performSearch(1, true);
        }
    }, []);

    const performSearch = useCallback((currentPage, currentHasMore) => {
        let searchPage = currentPage ?? page;
        let searchHasMore = currentHasMore ?? hasMore;

        if ((geoposition || searchHasMore) && !isSearching) {
            setIsSearching(true);
            return searchGeoProducts(
                searchTerm,
                geoposition,
                category,
                sortBy,
                searchPage,
                distance,
                priceFrom,
                priceTo,
                weight, // The server only needs the numeric value
                cbdFrom,
                cbdTo,
                thcFrom,
                thcTo,
                strainType
            )
                .then(handleSearchResults)
                .catch(error => {
                    console.error("Search error:", error);

                    if(retryCount <= 100){
                        performSearch(currentPage, currentHasMore);
                        setRetryCount(retryCount+1);
                    }
                });
        }
        return Promise.resolve();
    }, [
                                          geoposition, searchTerm, category, sortBy, page, hasMore, isSearching, searchGeoProducts,
                                          handleSearchResults, priceFrom, priceTo, weight, cbdFrom, cbdTo, thcFrom, thcTo, distance, strainType
                                      ]);

    const getBusinesses = useCallback(() => {
        if (!geoposition) return Promise.resolve();

        return searchGeoBusinesses(searchParams)
            .then(handleSetBusinesses)
            .catch(error => {
                console.error("Business search error:", error);
                setTimeout(function (){
                    //getBusinesses();
                }, 500);
                //getBusinesses();
            });
    }, [searchParams, geoposition, searchGeoBusinesses, handleSetBusinesses]);

    useEffect(() => {
        //console.log('Geoposition Changed');
        if (isApiDistanceUpdate) {
            // If this was an API update, just reset the flag and don't trigger a search
            setIsApiDistanceUpdate(false);
            return;
        }

        if (geoposition) {
            //console.log('Geoposition Changed 2');

            resetSearch();
            performSearch();
            getBusinesses();
        }
    }, [geoposition, category, searchTerm, sortBy, priceFrom, priceTo, weight, cbdFrom, cbdTo, thcFrom, thcTo, strainType, distance]);

    const fetchFilterCounts = useCallback(() => {
        if (geoposition) {
            getFilterCounts(geoposition, searchTerm, sortBy, category, distance, strainType, weight, priceFrom, priceTo)
                .then(counts => {
                    setFilterCounts(counts);
                })
                .catch(error => {
                    console.error("Error fetching filter counts:", error);
                    //fetchFilterCounts();
                });
        }
    }, [geoposition, searchTerm, sortBy, category, strainType, weight, priceFrom, priceTo, distance, getFilterCounts]);

    useEffect(() => {
        fetchFilterCounts();
    }, [fetchFilterCounts]);

    const handleFilterChange = () => {
        setPage(1);
        setHasMore(true);
    }

    const handleStrainTypeChange = (newStrainType) => {
        setStrainType(newStrainType);
        resetSearch();
    };

    const handleCBDChange = (from, to) => {
        setCbdFrom(from);
        setCbdTo(to);
        resetSearch();
    };

    const handleTHCChange = (from, to) => {
        setThcFrom(from);
        setThcTo(to);
        resetSearch();
    };

    const handleDistanceChange = (newDistance) => {
        if (distance === newDistance) {
            return;
        }
        setIsApiDistanceUpdate(false); // Ensure this is treated as a user change
        setDistance(newDistance);
        resetSearch();
    }

    const handlePriceChange = (from, to) => {
        setPriceFrom(from);
        setPriceTo(to);
        resetSearch();
    };

    const handleWeightChange = (newWeight, newWeightUnit) => {
        setWeight(newWeight);
        setWeightUnit(newWeightUnit);
        resetSearch();
    };

    const handleSearchTermChange = useCallback((term) => {
        setSearchTerm(term);
        resetSearch();
    }, []);

    const handleCategoryChange = (newCategory) => {
        console.log("CHANGING CATEGORY BRUH");
        console.log(newCategory);
        setCategory(newCategory.id);
        setCategoryName(newCategory.name);
        setMapMarker(newCategory.mapMarker);
        resetSearch();
        window.scrollTo(0, 0);

        if (category == newCategory.id) {
            console.log("Performing Search");
            getBusinesses();
            performSearch(1, true);
        } else {

        }

        /*setTimeout(function () {
            const navBarPosition = document.getElementById('ScrollPosition').offsetTop;
            const scrollTo = navBarPosition + 6;
            console.log({ navBarPosition });
            window.scrollTo(0, scrollTo);
        }, 333);*/

    };

    const handleSortByChange = (newSortBy) => {
        if(newSortBy == sortBy){
            return performSearch(1, true);
        }
        setSortBy(newSortBy);
        if(newSortBy === 'favorites' || newSortBy === 'recent'){
            setCategory('all');
            setCategoryName('All Categories');
        } else {
            setCategory(2);
            setCategoryName('Flower');
        }
        resetSearch();
    };



    const handleFullFilterVisible = useCallback((isVisible) => {
        handleShowHeader(isVisible); // When filter is visible, hide header and vice versa

        if (!isVisible) {
            document.body.style.overflowY = "hidden";
            document.getElementById('MainApp').style.overflowY = "hidden";
            document.getElementById('root').style.overflowY = "hidden";

        } else {
            document.body.style.overflowY = "auto";
            document.getElementById('MainApp').style.overflowY = "auto";
            document.getElementById('root').style.overflowY = "auto";
        }

    }, [handleShowHeader]);

    const bottomPadding = { paddingBottom: "400px" };

    return (

        <div className={styles.AppResults}>
            <SearchBar
                user={user}
                className='search-bar'
                ref={searchBarRef}
                geoposition={geoposition}
                onLocationChange={changeGeoPosition}
                onSearchResults={handleSearchResults}
                onSearchTermChange={handleSearchTermChange}
                onSortByChange={handleSortByChange}
            >
                {memoizedMap}
            </SearchBar>
            <div className={styles.AppExtraDivLargeScreens}></div>
            <div className={styles.AppMainList}>
                <div id="ScrollPosition"></div>
                <div
                    ref={navbarFilterRef}
                    className={`${styles.navbarFilterContainer} ${isNavbarFilterPinned ? styles.pinned : ''}`}
                    style={isNavbarFilterPinned ? { position: 'fixed', top: 0, } : null}
                >
                    <NavbarFilter
                        apiDistance={apiDistance}
                        sortBy={sortBy}
                        onFilterChange={handleFilterChange}
                        onFullFilterVisible={handleFullFilterVisible}
                        onTermChange={handleSearchTermChange}
                        onCategoryChange={handleCategoryChange}
                        onDistanceChange={handleDistanceChange}
                        onPriceChange={handlePriceChange}
                        onWeightChange={handleWeightChange}
                        onCBDChange={handleCBDChange}
                        onTHCChange={handleTHCChange}
                        onStrainTypeChange={handleStrainTypeChange}
                        filterCounts={filterCounts}
                        pinned={isNavbarFilterPinned}
                        showTour={showTour}
                        setShowTour={setShowTour}
                    />
                </div>
                {isNavbarFilterPinned && <div style={{ height: '54px' }}/>} {/* Placeholder to prevent content jump */}


                <div className={`${styles.productListWrap} product-list ${isNavbarFilterPinned ? styles.productListWrapPinned : ''}`} style={{
                    width: '100%',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    margin: '0 auto'
                }}>
                    {(noResults && !isSearching) && (
                        <>
                            {sortBy === 'favorites' && (
                                <div className={styles.noResults}>No deals in your favorites.<br/> Favorite a product for it to show up here.<br/> Try a different menu category or adjust your search.</div>
                            )}
                            {sortBy !== 'favorites' && (
                                <div className={styles.noResults}>No deals available right now for this category.<br/> Try a different menu category or adjust your search.</div>
                            )}
                        </>

                    )}
                    <ProductListVirtual
                        pinned={isNavbarFilterPinned}
                        category={category}
                        categoryName={categoryName}
                        priceFrom={priceFrom}
                        priceTo={priceTo}
                        selectedWeight={weight}
                        selectedWeightUnit={weightUnit}
                        page={page}
                        sortBy={sortBy}
                        products={products}
                        customStyle={{
                            paddingBottom: '400px', // Match the div width from screenshot
                            margin: '0 auto'  // Center the list
                        }}
                        customClassName={styles.productListVirtual}
                        loadMore={performSearch}
                        hasMore={hasMore}
                        isLoading={isSearching}
                        noDeals={noDeals}
                        showTour={showTour}
                        setShowTour={setShowTour}
                    />
                </div>
            </div>

        </div>

    );
}

export default Results;
